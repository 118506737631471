import React from 'react';
import axios from 'axios';

import './App.css';

class App extends React.Component {
  state = {
    advice: '',
  }

  componentDidMount() {
    this.fetchAdvice();
  }

  fetchAdvice = () => {
    axios.get('https://api.adviceslip.com/advice')
    .then((response) => {
      const {
        advice
      } = response.data.slip;

      this.setState({
        advice
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div className="app">
        <div className="card">
          <h1 className="heading">{this.state.advice}</h1>
          <button className="button" onClick={this.fetchAdvice}>
            <span>GIVE ME ADVICE!!!</span>
          </button>
       <div className="share">
          <p>
You like this? Support us by sharing this! ❤️
      </p>
       </div>
        <div
        style={ {
          textAlign: "center",
          marginBottom: 10,
        }}
        >
      Made with ♥ by{" "}
      <a
          href="https://www.facebook.com/jp.jphabs"
          style={ { cursor: "pointer" }}
          >
        Jphabs Khalifa
      </a>
      </div>
      </div>
      </div>

    );
  }
}

export default App;